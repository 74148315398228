import * as React from 'react';
import { useMemo, useEffect, useRef, useState, useCallback} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { fabric } from 'fabric';
import 'fabric-history';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate,
    faArrowRotateLeft,
    faArrowRotateRight,
    faArrowsUpDownLeftRight,
    faArrowUpRightAndArrowDownLeftFromCenter,
    faArrowDown,
    faArrowUp,
    faArrowLeft,
    faArrowRight,
    faMinus,
    faPlus,
    faArrowTurnLeft,
    faArrowTurnRight
} from '@fortawesome/pro-regular-svg-icons';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { actionCreators} from '../../redux';
import { getUserInfo, getUserJobId } from '../../redux/reducers/userInfoReducer';
import { Direction, Shape } from '../../enums/Enums';
import { IRenderIMGPosition, Result} from './IResultPage';
import CustomButton from '../button/Button';
import {
    onChangeFinalTaskStatus,
    setFinalArtwork,
    setFinalArtworkRenderPosition,
} from '../../redux/actionCreators';
import { uploadModifiedArtworkData } from '../../api/api';
import { getFinalArtwork, getFinalArtworkRenderPosition } from '../../redux/reducers/resultInfoReducer';
import { SAFETY_LINE, BLEED_LINE } from '../../constants/constants';

import './ResultPage.scss';

const SCALE_FACTOR =  _.isUndefined(process.env.REACT_APP_SCALE_FACTOR) ? 0.05 : +process.env.REACT_APP_SCALE_FACTOR;
const DELTA_POSITION = _.isUndefined(process.env.REACT_APP_DELTA_POSITION) ? 5 : +process.env.REACT_APP_DELTA_POSITION;
const MIN_SCALE = 0;
const CANVAS_PADDING = 30;
const STATIC_LINE_OFFSET = 15;

const updateObjectsPosition = (canvas: fabric.Canvas, newZoom: number, previousZoom: number) => {
    canvas.getObjects().forEach((o: fabric.Object) => {
        const tempCanvasDimensionsZoom = 1 + (1 - newZoom);

        let width = canvas.width ? canvas.width / newZoom * tempCanvasDimensionsZoom : 0;
        let height = canvas.height ? canvas.height / newZoom * tempCanvasDimensionsZoom : 0;
        if (newZoom > 1) {
            width = canvas.width ? canvas.width * newZoom : 0;
            height = canvas.height ? canvas.height * newZoom : 0;
        }

        if ([ObjectName.CUT_LINE, ObjectName.BLEED_LINE, ObjectName.SAFETY_LINE, ObjectName.DASHED_CUT_LINE].includes(o.name as ObjectName)) {
            if (o.name === ObjectName.CUT_LINE) {
                o.setOptions({
                    width: width,
                    height: height,
                });
            }
            canvas.viewportCenterObject(o);
        }

        if (!_.isNil(o.top) && !_.isNil(o.left) && !_.isNil(o.width) &&
            !_.isNil(canvas.width) && !_.isNil(canvas.height) && o.name === ObjectName.ARTWORK) {
            o.setOptions({
                top: canvas.height / newZoom / 2 + o.top - canvas.height / previousZoom / 2,
                left: canvas.width / newZoom / 2 + o.left - canvas.width / previousZoom / 2,
            });
            o.setCoords();
        }
        if (o.top && o.left && o.name === ObjectName.SIZE_GROUP) {
            const cutLine = canvas.getObjects().find((o: fabric.Object) => o.name === ObjectName.DASHED_CUT_LINE);
            let top = cutLine?.top || 0;
            let left = cutLine?.left || 0;
            if (cutLine?.type === 'ellipse') {
                top -= (cutLine as any)?.ry;
                left -= (cutLine as any)?.rx;
            }
            if (cutLine?.left && cutLine?.top) {
                o.setOptions({
                    top,
                    left,
                });
                o.setCoords();
            }
        }
    });
};

fabric.util.object.extend(fabric.Canvas.prototype, {
    _historySaveAction: function (e: any) {
        if (this.historyProcessing) return;
        if (!e || (e.target && !e.target.excludeFromExport)) {
            const current = this.historyNextState;
            let next = this._historyNext();
            try {
                const nextObject = JSON.parse(next);
                nextObject.zoom = this.getZoom();
                next = JSON.stringify(nextObject);
            } catch (e) { /* Handle error to save history even if zoom set failed */ }
            this.historyUndo.push(current);
            this.historyNextState = next;
            this.historyRedo = [];
            this.fire('history:append', {json: current});
        }
    },
    undo: function (callback: any) {
        // The undo process will render the new states of the objects
        // Therefore, object:added and object:modified events will triggered again
        // To ignore those events, we are setting a flag.
        this.historyProcessing = true;

        const history = this.historyUndo.pop();

        if (history) {
            // Push the current state to the redo history
            this.historyRedo.push(this.historyNextState);
            this.historyNextState = history;
            this._loadHistory(history, 'history:undo', callback);
        } else {
            this.historyProcessing = false;
        }
    },
    redo: function (callback: any) {
        // The undo process will render the new states of the objects
        // Therefore, object:added and object:modified events will triggered again
        // To ignore those events, we are setting a flag.
        this.historyProcessing = true;
        const history = this.historyRedo.pop();
        if (history) {
            // Every redo action is actually a new action to the undo history
            this.historyUndo.push(this.historyNextState);
            this.historyNextState = history;
            this._loadHistory(history, 'history:redo', callback);
        } else {
            this.historyProcessing = false;
        }
    },
    resetHistory: function () {
        Object.keys(this._historyEvents()).forEach((event) => {
            this.__eventListeners[event] = [];
        });
        this._historyInit();
    },
    historySaveAction: function (target: any) {
        this._historySaveAction({ target });
    },
    _historyInit: function () {
        this.historyUndo = [];
        this.historyRedo = [];
        this.extraProps = ['selectable', 'editable', 'evented', 'hasControls', 'name', 'scaleX', 'scaleY'];
        let next = this._historyNext();
        try {
            const nextObject = JSON.parse(next);
            nextObject.zoom = this.getZoom();
            next = JSON.stringify(nextObject);
        } catch (e) { /* Handle error to save history even if zoom set failed */ }
        this.historyNextState = next;
        this.on(this._historyEvents());
    },
    _loadHistory: function (history: string, event: string, callback: any) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;

        this.loadFromJSON(history, function () {
            try {
                const historyObject = JSON.parse(history);
                if (!historyObject.zoom) {
                    throw new Error();
                }
                that._applyZoomAfterLoad(that.getZoom(), historyObject.zoom);
            } catch (e) {
                console.error('Failed to restore canvas zoom');
            }
            that.renderAll();
            that.fire(event);
            that.historyProcessing = false;

            if (callback && typeof callback === 'function') callback();
        });
    },
    _applyZoomAfterLoad: function (currentZoom: number, historyZoom: number) {
        updateObjectsPosition(this, currentZoom, historyZoom);
    },
});

fabric.Object.NUM_FRACTION_DIGITS = 10;

enum ObjectName {
    DASHED_CUT_LINE = 'newDashedCutLine',
    CUT_LINE = 'newСutLine',
    BLEED_LINE = 'newBleedLine',
    SAFETY_LINE = 'newSafetyLine',
    SIZE_GROUP = 'sizeGroup',
    ARTWORK = 'artworkIMG',
}



const ResultPage:  React.FC<Result>  = (props) => {
    const dispatch = useDispatch();
    const dispatchThunk = useDispatch<ThunkDispatch<any, any, any>>();

    const userInfo = useSelector(getUserInfo);
    const jobId = useSelector(getUserJobId);

    const refContainer = useRef(null);
    const finalCanvas = useSelector(getFinalArtwork);
    const finalCanvasRenderPosition = useSelector(getFinalArtworkRenderPosition);
    const [canvas, setCanvas] = useState<fabric.Canvas | any | null>(null);
    const [artworkIMG, setArtworkIMG] = useState<fabric.Image | null>(null);
    const [activeMenuItem, setActiveMenuItem] = useState('rotate');
    const [scaleByPercentage, setScaleByPercentage] = useState('');
    const [coefficientScaling, setCoefficientScaling] = useState<number>(1);
    const [fitToScreenValue, setFitToScreenValue] = useState<number | number[]>(100);
    const [dimensions, setDimensions] = useState<any>({
        width: null,
        height: null,
    });
    const [renderIMGPosition, setRenderIMGPosition] = useState<IRenderIMGPosition>({
        left: 0,
        top: 0,
        scale: 1,
        xc: 0,
        yc: 0,
        height: 0,
        width: 0,
    });

    const {
        onChangeShape,
        onChangeWidth,
        onChangeHeight,
        onChangeLoadingStatus,
        onChangeTaskStatus,
        onChangeUploadStatusInfo
    } = useMemo(
        () => bindActionCreators(actionCreators, dispatch),
        [dispatch]
    );
    const [isActiveUndo, setIsActiveUndo] = useState(false);
    const [isActiveRedo, setIsActiveRedo] = useState(false);

    useEffect(() => {
        if (refContainer.current) {
            setDimensions({
                width: refContainer.current['offsetWidth'],
                height: refContainer.current['offsetHeight'],
            });
        }
    }, [refContainer.current]);

    const generateCutLine = useCallback((newCanvas: fabric.Canvas, coefficientBaseScaling: number) => {
        if (newCanvas) {
            let newСutLine;
            const rect =  new fabric.Rect({
                width: dimensions.width + 1, height: dimensions.height + 1, fill: '#dce9fc', left: -1, top: -1,
                evented: false, opacity: 0.4, selectable: false, hasControls: false
            });

            switch (userInfo.shape?.value) {
            case Shape.Circle :
                newСutLine = new fabric.Circle({
                    radius: userInfo.width / 2 * coefficientBaseScaling ,
                    originX: 'center',
                    originY: 'center',
                    fill: '', inverted: true, hoverCursor: 'auto'});
                break;
            case Shape.Oval :
                newСutLine = new fabric.Ellipse({
                    rx: userInfo.width / 2 * coefficientBaseScaling,
                    ry: userInfo.height / 2 * coefficientBaseScaling,
                    originX: 'center',
                    originY: 'center',
                    fill: '', inverted: true, hoverCursor: 'auto'});
                break;
            default:
                newСutLine = new fabric.Rect({
                    width: userInfo.width * coefficientBaseScaling,
                    height : userInfo.height * coefficientBaseScaling,
                    originX: 'center',
                    originY: 'center',
                    fill: '', inverted: true, hoverCursor: 'auto'});
                break;
            }
            rect.setOptions({ name: "newСutLine"});
            rect.clipPath = newСutLine;
            newCanvas.insertAt(rect, 0, false);
            rect.bringToFront();
            return newCanvas;
        }
    }, [userInfo, dimensions]);

    const generateLine = useCallback((coefficientBaseScaling: number, shift: number) => {
        switch (userInfo.shape?.value) {
        case Shape.Circle :
            return new fabric.Circle({
                radius: (+userInfo.width / 2 + shift / 2) * coefficientBaseScaling,
                strokeDashArray: [6],
                fill: '',
                selectable: false,
                hoverCursor: 'auto',
                evented: false,
            });
        case Shape.Oval :
            return new fabric.Ellipse({
                rx: (+userInfo.width / 2 + shift / 2) * coefficientBaseScaling,
                ry: (+userInfo.height / 2 + shift / 2) * coefficientBaseScaling,
                strokeDashArray: [6],
                originX: 'center',
                originY: 'center',
                fill: '',
                selectable: false,
                hoverCursor: 'auto',
                evented: false,
            });
        default:
            return new fabric.Rect({
                width: (+userInfo.width + shift) * coefficientBaseScaling,
                height: (+userInfo.height + shift) * coefficientBaseScaling,
                strokeDashArray: [6],
                fill: '',
                selectable: false,
                hoverCursor: 'auto',
                evented: false
            });
        }
    }, [userInfo]);

    const generateDashedCutLine = useCallback((newCanvas: fabric.Canvas, coefficientBaseScaling: number) => {
        if (newCanvas) {
            const newCutLine= generateLine(coefficientBaseScaling, 0);
            newCutLine.setOptions({ name: "newDashedCutLine", stroke: "rgb(206,7,180)", strokeDashArray: [0]});
            newCanvas.centerObject(newCutLine);
            newCanvas.insertAt(newCutLine, 1, false);
            return newCanvas;
        }
    }, [userInfo]);

    const generateSafetyLine = useCallback((newCanvas: fabric.Canvas, coefficientBaseScaling: number) => {
        if (newCanvas) {
            const newSafetyLine= generateLine(coefficientBaseScaling, - SAFETY_LINE * 2);
            newSafetyLine.setOptions({ name: "newSafetyLine", stroke: "#21A112"});
            newCanvas.centerObject(newSafetyLine);
            newCanvas.insertAt(newSafetyLine, 2, false);
            return newCanvas;
        }
    }, [userInfo]);

    const generateBleedLine = useCallback((newCanvas: fabric.Canvas, coefficientBaseScaling: number) => {
        if (newCanvas) {
            const newBleedLine = generateLine(coefficientBaseScaling, BLEED_LINE * 2);
            newBleedLine.setOptions({ name: "newBleedLine", stroke: "rgb(6,122,255)"});
            newCanvas.centerObject(newBleedLine);
            newCanvas.insertAt(newBleedLine, 3, false);
            return newCanvas;
        }
    }, [userInfo]);

    const generateSizeLines = useCallback((newCanvas: fabric.Canvas, coefficientBaseScaling: number) => {
        if (newCanvas) {
            const SMALL_LINE_SIZE = 6;
            const TEXT_WIDTH = 80;
            const LINE_OFFSET = BLEED_LINE * coefficientBaseScaling + STATIC_LINE_OFFSET;

            newCanvas.getObjects().forEach(function(o: any) {
                if(o.name === "newDashedCutLine" && o.left && o.top && o.width && o.height) {
                    let top = o.top;
                    let left = o.left;
                    const height = o.height;
                    const width = o.width;
                    if (userInfo.shape?.value === Shape.Oval && o.ry && o.rx) {
                        top = o.top - o.ry;
                        left = o.left  - o.rx;
                    }
                    const horizontalSizeLine1 = new fabric.Line([left ,(top + height + LINE_OFFSET),
                        (left + width/2 - TEXT_WIDTH/2), (top + height + LINE_OFFSET)], {
                        stroke: '#1C182D',
                    });
                    const horizontalSizeLine2 = new fabric.Line([(left + width/2 + TEXT_WIDTH/2) ,(top + height + LINE_OFFSET),
                        (left + width), (top + height + LINE_OFFSET)], {
                        stroke: '#1C182D',
                    });
                    const smallLeftHorizontalSizeLine = new fabric.Line([left ,
                        (top + height + LINE_OFFSET - SMALL_LINE_SIZE / 2),
                        left, (top + height + LINE_OFFSET + SMALL_LINE_SIZE / 2)], {
                        stroke: '#1C182D',
                    });
                    const smallRightHorizontalSizeLine = new fabric.Line([(left + width) ,
                        (top + height + LINE_OFFSET - SMALL_LINE_SIZE / 2),
                        (left + width), (top + height + LINE_OFFSET + SMALL_LINE_SIZE / 2)], {
                        stroke: '#1C182D',
                    });
                    const horizontalGroupTextBox = new fabric.Textbox(`  ${userInfo.width}mm  `, {
                        left: left + width/2 - TEXT_WIDTH/2,
                        top: top + height + LINE_OFFSET - 24/2,
                        fill: '#1C182D',
                        width: TEXT_WIDTH,
                        fontSize: 17,
                        fontWeight: 400,
                        fontFamily: "Montserrat",
                    });
                    const horizontalGroup = new fabric.Group([horizontalSizeLine1, horizontalSizeLine2,
                        smallLeftHorizontalSizeLine, smallRightHorizontalSizeLine, horizontalGroupTextBox], {
                        left: left,
                        top: top + height + LINE_OFFSET - SMALL_LINE_SIZE / 2,
                        selectable: false,
                        hoverCursor: 'auto',
                        evented: false,
                        name: "horizontalGroupTextBox"
                    });

                    const verticalSizeLine1 = new fabric.Line([(left + width + LINE_OFFSET), top,
                        (left + width + LINE_OFFSET), (top + height/2 - 24/2)], {
                        stroke: '#1C182D',
                    });
                    const verticalSizeLine2 = new fabric.Line([(left + width + LINE_OFFSET),
                        (top + height/2 + 24/2), (left + width + LINE_OFFSET), (top + height)], {
                        stroke: '#1C182D',
                    });
                    const smallLeftVerticalSizeLine = new fabric.Line([
                        (left + width + LINE_OFFSET - SMALL_LINE_SIZE / 2), top,
                        (left + width + LINE_OFFSET + SMALL_LINE_SIZE / 2), top], {
                        stroke: '#1C182D',
                    });
                    const smallRightVerticalSizeLine = new fabric.Line([
                        (left + width + LINE_OFFSET - SMALL_LINE_SIZE / 2), (top + height),
                        (left + width + LINE_OFFSET + SMALL_LINE_SIZE / 2), (top + height)], {
                        stroke: '#1C182D',
                    });
                    const verticalGroupTextBox = new fabric.Textbox(`  ${userInfo.height}mm  `, {
                        left: left + width + LINE_OFFSET - TEXT_WIDTH/2,
                        top: top + height/2 - 24/2,
                        fill: '#1C182D',
                        width: TEXT_WIDTH,
                        fontSize: 17,
                        fontWeight: 400,
                        fontFamily: "Montserrat",
                    });
                    const verticalGroup = new fabric.Group([verticalSizeLine1, verticalSizeLine2,
                        smallLeftVerticalSizeLine, smallRightVerticalSizeLine, verticalGroupTextBox], {
                        left: (left + width + LINE_OFFSET - SMALL_LINE_SIZE / 2),
                        top: top,
                        selectable: false,
                        hoverCursor: 'auto',
                        evented: false,
                        name: "verticalGroupTextBox",
                    });

                    const sizeGroup = new fabric.Group([horizontalGroup, verticalGroup], {
                        left: left,
                        top: top,
                        selectable: false,
                        hoverCursor: 'auto',
                        evented: false,
                        name: "sizeGroup",
                    });

                    newCanvas.insertAt(sizeGroup, 5, false);
                }
            });
            return newCanvas;
        }
    }, [userInfo]);

    useEffect(() => {
        if (!canvas && props.previewImage?.imageUrl && !_.isNil(dimensions.width) && !_.isNil(dimensions.height)) {
            if (!_.isNull(finalCanvas)) {
                setIsActiveUndo(finalCanvas.canUndo());
                setIsActiveRedo(finalCanvas.canRedo());
                const jsonString = JSON.stringify(finalCanvas.toJSON(['selectable', 'name',
                    'evented', 'historyNextState', 'historyRedo', 'historyUndo', 'hasControls', 'preserveObjectStacking']));
                const newCanvas: any = new fabric.Canvas('c');
                newCanvas.loadFromJSON(jsonString, () => {
                    const newArtwork = newCanvas.getObjects().find((o: fabric.Object) => o.name === 'artworkIMG') || artworkIMG;
                    setArtworkIMG(newArtwork);
                });
                newCanvas.resetHistory();
                const canvasZoom = finalCanvas.getZoom();
                newCanvas.setZoom(canvasZoom);
                setFitToScreenValue(Math.trunc(canvasZoom * 100));
                setCanvas(newCanvas);
                setRenderIMGPosition(finalCanvasRenderPosition);
                const scaleX = dimensions.width / (+userInfo.width + 2 * CANVAS_PADDING);
                const scaleY = dimensions.height / (+userInfo.height + 2 * CANVAS_PADDING);
                const coefficientBaseScaling = Math.min(scaleX, scaleY);
                setCoefficientScaling(coefficientBaseScaling);
            } else {
                let newCanvas : any = new fabric.Canvas('c', {
                    backgroundColor: '#ffffff',
                    selectionColor: 'rgba(88,130,239,0.7)',
                    selectionLineWidth: 1,
                    svgViewportTransformation: true,
                    preserveObjectStacking: true,
                });

                const scaleX = dimensions.width / (+userInfo.width + 2 * CANVAS_PADDING);
                const scaleY = dimensions.height / (+userInfo.height + 2 * CANVAS_PADDING);
                const coefficientBaseScaling = Math.min(scaleX, scaleY);
                setCoefficientScaling(coefficientBaseScaling);

                const canvasWithCutLine = generateCutLine(newCanvas, coefficientBaseScaling);
                if (canvasWithCutLine) {
                    newCanvas = canvasWithCutLine;
                }
                const canvasWithDashedCutLine= generateDashedCutLine(newCanvas, coefficientBaseScaling);
                if (canvasWithDashedCutLine) {
                    newCanvas = canvasWithDashedCutLine;
                }


                const canvasWithBleedLine = generateBleedLine(newCanvas, coefficientBaseScaling);
                if (canvasWithBleedLine) {
                    newCanvas = canvasWithBleedLine;
                }

                const canvasWithSafetyLine = generateSafetyLine(newCanvas, coefficientBaseScaling);
                if (canvasWithSafetyLine) {
                    newCanvas = canvasWithSafetyLine;
                }

                const canvasWithSizeLines = generateSizeLines(newCanvas, coefficientBaseScaling);
                if (canvasWithSizeLines) {
                    newCanvas = canvasWithSizeLines;
                }

                fabric.Image.fromURL(props.previewImage.imageUrl, function(img) {
                    img.setOptions({ name: "artworkIMG" });
                    newCanvas.centerObject(img);
                    if (img.width && img.height) {
                        const scaleXImg = ((props.width || (+userInfo.width + BLEED_LINE * 2)) * coefficientBaseScaling) / img.width;
                        const scaleYImg = ((props.height || (+userInfo.height + BLEED_LINE * 2)) * coefficientBaseScaling) / img.height;
                        const coefficientBaseScalingImg = Math.min(scaleXImg, scaleYImg);

                        img.scaleToWidth(img.width * coefficientBaseScalingImg);
                        img.scaleToHeight(img.height * coefficientBaseScalingImg);
                    }

                    newCanvas.centerObject(img);
                    const matrix = img.calcOwnMatrix();
                    setRenderIMGPosition({
                        left: img.left ?? 0,
                        top: img.top ?? 0,
                        scale: matrix[0],
                        xc: matrix[4],
                        yc: matrix[5],
                        height: (img?.height || 0) * (img?.scaleY || 1),
                        width: (img?.width || 0) * (img?.scaleX || 1),
                    });
                    newCanvas.insertAt(img, 0, false);
                    setArtworkIMG(img);
                    newCanvas.resetHistory();
                });

                setCanvas(newCanvas);
            }
        }
    }, [props.previewImage, dimensions, canvas, userInfo, setCoefficientScaling, finalCanvasRenderPosition]);

    useEffect(() => {
        if (!artworkIMG && canvas) {
            canvas.getObjects().forEach(function(o: any) {
                if (o.name === "artworkIMG") {
                    setArtworkIMG(o);
                }
            });
        }
    }, [canvas, artworkIMG]);

    const onDeselectArtwork = (options: fabric.IEvent) =>{
        if (options.target && canvas) {
            canvas.getObjects().forEach(function(o: fabric.Object) {
                if(o.name === "newСutLine") {
                    canvas.setActiveObject(o);
                }
            });
        }
    };

    const onSelectCutLine = (options: fabric.IEvent) =>{
        if ((_.isNil(options.target) || options.target?.name !== "artworkIMG") && canvas) {
            canvas.getObjects().forEach(function(o: fabric.Object) {
                if (o.name === "newСutLine") {
                    canvas.setActiveObject(o);
                }
            });
        }
    };

    useEffect(() => {
        if (canvas && artworkIMG) {
            canvas.on('object:modified', function(options: fabric.IEvent) {
                setIsActiveUndo(canvas.canUndo());
                setIsActiveRedo(canvas.canRedo());
            });

            canvas.on('mouse:down', function(options: fabric.IEvent) {
                onSelectCutLine(options);
            });

            canvas.on('object:moving', function(options: fabric.IEvent) {
                onDeselectArtwork(options);
            });
        }
    },[canvas, artworkIMG]);

    const onCheckAnother = useCallback(() => {
        onChangeWidth('');
        onChangeHeight('');
        onChangeShape(null);
        onChangeTaskStatus( {status: ''});
        onChangeLoadingStatus(false);
        dispatchThunk(setFinalArtwork(null));
    }, [onChangeTaskStatus]);

    useEffect(() => {
        return () => {
            onChangeUploadStatusInfo({
                progress: 0,
                estimated: 0
            });
            setArtworkIMG(null);
            setCanvas(null);
            refContainer.current = null;
        };
    }, []);

    const onFinish = useCallback(() => {
        if (artworkIMG && !_.isNull(canvas)) {
            const finalArtwork = canvas.getObjects().find((o: fabric.Object) => o.name === 'artworkIMG') || artworkIMG;
            dispatchThunk(setFinalArtwork(canvas));
            dispatchThunk(setFinalArtworkRenderPosition(renderIMGPosition));
            dispatchThunk(onChangeFinalTaskStatus({ status: 'uploading' }));
            const matrix: number[] = finalArtwork.calcOwnMatrix();
            const canvasZoom = canvas.getZoom();
            const newXC = canvas.width / 2  + matrix[4] - canvas.width / canvasZoom / 2;
            const newYC = canvas.height / 2  + matrix[5] - canvas.height / canvasZoom / 2;

            const scaledImageWidth2 = (finalArtwork?.width || 0) * (finalArtwork?.scaleX || 1);
            const scaledImageHeight2 = (finalArtwork?.height || 0) * (finalArtwork?.scaleY || 1);
            const convertWidthCoefficient = (props.width || +userInfo.width + BLEED_LINE * 2) / (renderIMGPosition.width || 1);
            const convertHeightCoefficient = (props.height || +userInfo.height + BLEED_LINE * 2) / (renderIMGPosition.height || 1);

            const modifiedMatrix = matrix.map((el, index) => {
                if (index === 4) {
                    return (-renderIMGPosition.xc + renderIMGPosition.width / 2 + newXC - scaledImageWidth2 / 2) * convertWidthCoefficient;
                }
                if (index === 5) {
                    return (renderIMGPosition.yc + renderIMGPosition.height / 2 - newYC - scaledImageHeight2 / 2) * convertHeightCoefficient;
                }
                return el / renderIMGPosition.scale;
            }).map(el => parseFloat(el.toFixed(20)));

            const fetchData = async () => {
                await dispatchThunk(uploadModifiedArtworkData({
                    matrix: modifiedMatrix,
                    width: (+userInfo.width + BLEED_LINE * 2),
                    height: (+userInfo.height + BLEED_LINE * 2),
                }, jobId));

                props.onChangeActiveTab('preview');
            };
            fetchData().catch(console.error);
        }
    }, [canvas, artworkIMG, renderIMGPosition, props.width, props.height]);

    const saveAction = useCallback(() => {
        artworkIMG?.setCoords();
        canvas.renderAll();
        canvas.historySaveAction(artworkIMG);
        setIsActiveUndo(canvas.canUndo());
        setIsActiveRedo(canvas.canRedo());
    }, [canvas, artworkIMG]);


    const scaleDown = useCallback(() => {
        if (artworkIMG && canvas && !_.isNil(artworkIMG.left) && !_.isNil(artworkIMG.width) && !_.isNil(artworkIMG.top) && !_.isNil(artworkIMG.height)) {
            const originScale = artworkIMG.getObjectScaling();
            if (originScale.scaleX - SCALE_FACTOR > MIN_SCALE) {
                const angle = artworkIMG?.angle || 0;
                artworkIMG.rotate(0);
                const scale = originScale.scaleX - SCALE_FACTOR;
                artworkIMG.scale(scale);
                const left = artworkIMG.left + artworkIMG.width * SCALE_FACTOR / 2;
                const top = artworkIMG.top + artworkIMG.height * SCALE_FACTOR / 2;
                artworkIMG.set({ left, top });
                artworkIMG.rotate(angle);
                saveAction();
            }
        }
    }, [canvas, artworkIMG, saveAction]);

    const scale = useCallback(() => {
        if (artworkIMG && canvas && !_.isNil(artworkIMG.left) && !_.isNil(artworkIMG.width) && !_.isNil(artworkIMG.top) && !_.isNil(artworkIMG.height)) {
            const bleedLine = canvas.getObjects().find((o: fabric.Object) => o.name === ObjectName.BLEED_LINE);
            const initialScaleX = (bleedLine?.width || 1) / artworkIMG.width;
            const initialScaleY = (bleedLine?.height || 1) / artworkIMG.height;

            const scale = +scaleByPercentage === 0 ? 1/100 : +scaleByPercentage/100;
            if (+scaleByPercentage === 0) setScaleByPercentage('1');
            const { scaleX, scaleY } = artworkIMG.getObjectScaling();
            const userScaleX = scaleX / initialScaleX;
            const userScaleY = scaleY / initialScaleY;
            const coefX = userScaleX > userScaleY ? 1 : userScaleX / userScaleY;
            const coefY = userScaleX > userScaleY ? userScaleY / userScaleX : 1;
            const newScaleX = scale * coefX * initialScaleX;
            const newScaleY = scale * coefY * initialScaleY;
            if (scale > MIN_SCALE) {
                const angle = artworkIMG?.angle || 0;
                artworkIMG.rotate(0);
                artworkIMG.scaleX = newScaleX;
                artworkIMG.scaleY = newScaleY;
                const left = artworkIMG.left - artworkIMG.width * (newScaleX - scaleX) / 2;
                const top = artworkIMG.top - artworkIMG.height * (newScaleY - scaleY) / 2;
                artworkIMG.set({ left, top });
                artworkIMG.rotate(angle);
                saveAction();
            }
        }
    }, [scaleByPercentage, canvas, artworkIMG, saveAction, coefficientScaling]);

    const scaleByFactor = useCallback((coefficient = 1) =>() => {
        if (artworkIMG && canvas && !_.isNil(artworkIMG.left) && !_.isNil(artworkIMG.width) && !_.isNil(artworkIMG.top) && !_.isNil(artworkIMG.height)) {
            const originScale = artworkIMG.getObjectScaling();
            if (originScale.scaleX + coefficient * SCALE_FACTOR > MIN_SCALE &&
              originScale.scaleY + coefficient * SCALE_FACTOR > MIN_SCALE) {
                const angle = artworkIMG?.angle || 0;
                artworkIMG.rotate(0);
                artworkIMG.scaleX = originScale.scaleX + coefficient * SCALE_FACTOR;
                artworkIMG.scaleY = originScale.scaleY + coefficient * SCALE_FACTOR;
                const left = artworkIMG.left - artworkIMG.width * coefficient * SCALE_FACTOR / 2;
                const top = artworkIMG.top - artworkIMG.height * coefficient * SCALE_FACTOR / 2;
                artworkIMG.set({ left, top });
                artworkIMG.rotate(angle);
                saveAction();
            }
        }
    }, [canvas, artworkIMG, saveAction]);

    const fitToLine = useCallback((shift: number) => {
        if (artworkIMG && canvas && artworkIMG.width && artworkIMG.height && !_.isNil(artworkIMG.left) &&
            !_.isNil(artworkIMG.top) && artworkIMG.scaleX && artworkIMG.scaleY) {
            const { scaleX, scaleY } = artworkIMG.getObjectScaling();
            const angle = (artworkIMG?.angle || 0) * Math.PI / 180;
            const width = Math.abs(artworkIMG.width * Math.cos(angle)) + Math.abs(artworkIMG.height * Math.sin(angle));
            const height = Math.abs(artworkIMG.width * Math.sin(angle)) + Math.abs(artworkIMG.height * Math.cos(angle));
            const scaleXImg = ((+userInfo.width + shift) * coefficientScaling) / width;
            const scaleYImg = (+userInfo.height + shift) * coefficientScaling / height;
            const coefficientBaseScalingImg = scaleXImg < scaleYImg ? scaleXImg : scaleYImg;

            const canvasZoom = canvas.getZoom();
            artworkIMG.scaleToWidth(width * coefficientBaseScalingImg * canvasZoom);
            artworkIMG.scaleToHeight(height * coefficientBaseScalingImg * canvasZoom);
            artworkIMG.setOptions({
                top: artworkIMG.height * scaleY / 2 - artworkIMG.height * artworkIMG.scaleY / 2 + artworkIMG.top,
                left: artworkIMG.width * scaleX / 2 - artworkIMG.width * artworkIMG.scaleX / 2 + artworkIMG.left,
            });
            artworkIMG.viewportCenter();
            saveAction();
        }
    }, [canvas, artworkIMG, coefficientScaling, saveAction]);

    const rotateTo = useCallback((direction: string) => {
        if(artworkIMG && canvas) {
            const currentAngle = artworkIMG.angle;
            if (!_.isNil(currentAngle)) {
                switch (direction) {
                case Direction.Left : artworkIMG.rotate(currentAngle - 90);
                    saveAction();
                    break;
                case Direction.Right : artworkIMG.rotate(currentAngle + 90);
                    saveAction();
                    break;
                default: break;
                }
            }
        }
    }, [canvas, artworkIMG, saveAction]);

    const resetRotation = useCallback(() => {
        if(artworkIMG && canvas) {
            artworkIMG.rotate(0);
            saveAction();
        }
    }, [canvas, artworkIMG, saveAction]);

    const moveTo = useCallback((direction: string) => {
        if (canvas && artworkIMG && artworkIMG.top && artworkIMG.left && artworkIMG.width && artworkIMG.height) {
            const zoom = canvas.getZoom();
            const { left, top, width, height} = artworkIMG.getBoundingRect();
            const delta_position_with_canvas = DELTA_POSITION * zoom;
            switch (direction) {
            case Direction.Top:
                if (top - delta_position_with_canvas > 0) {
                    artworkIMG.set({
                        top: artworkIMG.top - delta_position_with_canvas
                    });
                    saveAction();
                }
                break;
            case Direction.Right:
                if (left + width + delta_position_with_canvas < dimensions.width) {
                    artworkIMG.set({
                        left: artworkIMG.left + delta_position_with_canvas
                    });
                    saveAction();
                }
                break;
            case Direction.Bottom:
                if (top + height + delta_position_with_canvas < dimensions.height) {
                    artworkIMG.set({
                        top: artworkIMG.top + delta_position_with_canvas
                    });
                    saveAction();
                }
                break;
            case Direction.Left:
                if (left - delta_position_with_canvas > 0) {
                    artworkIMG.set({
                        left: artworkIMG.left - delta_position_with_canvas
                    });
                    saveAction();
                }
                break;
            default:
                break;
            }

        }
    }, [canvas, artworkIMG, dimensions, saveAction]);

    const changeActiveMenuItem = useCallback((item: string) => {
        setActiveMenuItem(item);
    }, [setActiveMenuItem]);

    const handleInputChange = useCallback((e: any) => {
        setScaleByPercentage(e.target.value);
    }, [setScaleByPercentage]);

    const blockInvalidChar = (e: any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const renderActiveGroup = useCallback(() => {
        switch (activeMenuItem) {
        case 'rotate':
            return (<div>
                <h3>Rotate artwork</h3>
                <div className="description">The artwork can also be moved manually by grabbing the rotate handle.</div>
                <div className="btn-group">
                    <CustomButton iconFA={faArrowRotateLeft} label='-90°' className='btn' disabled={false} active={true} onClick={() => rotateTo(Direction.Left)}/>
                    <CustomButton iconFA={faArrowRotateRight} label='+90°' className='btn' disabled={false} active={true} onClick={() => rotateTo(Direction.Right)}/>
                </div>
                <div className="reset" onClick={resetRotation} >reset rotation</div>
            </div>);
        case 'move':
            return (
                <div className="move">
                    <h3>Move artwork</h3>
                    <div className="description">The artwork can also be moved manually by grabbing the preview image.</div>
                    <div className="top-btn">
                        <CustomButton iconFA={faArrowUp} className='btn' disabled={false} active={true} onClick={() => moveTo(Direction.Top)}/>
                    </div>
                    <div className="btn-group">
                        <CustomButton iconFA={faArrowLeft} className='btn' disabled={false} active={true} onClick={() => moveTo(Direction.Left)}/>
                        <CustomButton iconFA={faArrowDown} className='btn' disabled={false} active={true} onClick={() => moveTo(Direction.Bottom)}/>
                        <CustomButton iconFA={faArrowRight} className='btn' disabled={false} active={true} onClick={() => moveTo(Direction.Right)}/>
                    </div>
                </div>
            );
        case 'scale':
            return (
                <div className="scale">
                    <h3>Scale artwork</h3>
                    <div className="description">The artwork can also be scaled manually by grabbing one of the drag handles</div>
                    <h4>Scale</h4>
                    <div className="btn-group">
                        <CustomButton iconFA={faMinus} className='btn' disabled={false} active={true} onClick={scaleByFactor(-1)}/>
                        <CustomButton iconFA={faPlus} className='btn' disabled={false} active={true} onClick={scaleByFactor()}/>
                    </div>
                    <h4>Scale to fit</h4>
                    <div className="btn fit-to safety-line" onClick={() => fitToLine(- 2 * SAFETY_LINE)} >
                        <div className="safety-line-dots"/>
                        <div>scale to fit safety line</div>
                    </div>
                    <div className="btn fit-to bleed-line" onClick={() => fitToLine(2 * BLEED_LINE)} >
                        <div className="bleed-line-dots"/>
                        <div>scale to fit bleed line</div>
                    </div>
                    <div className="btn fit-to cut-line" onClick={() => fitToLine(0)} >
                        <div className="cut-line-dots"/>
                        <div>scale to fit cut line</div>
                    </div>
                    <h4>Scale by percentage:</h4>
                    <div className="scale-by-percentage">
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            type="number"
                            value={scaleByPercentage}
                            onKeyDown={blockInvalidChar}
                            onChange={(e) => handleInputChange(e)}
                        />
                        <CustomButton name='scale' className='btn' disabled={false} active={true} onClick={scale}/>
                    </div>
                </div>
            );
        default: return (<div/>);
        }
    }, [activeMenuItem, scaleByPercentage, canvas, artworkIMG]);

    const handleFitToScreenChange = useCallback((e: Event | any, newValue: any, previousZoomValue?: number) => {
        setFitToScreenValue(newValue);
        if (canvas) {
            const previousZoom = previousZoomValue || canvas.getZoom();
            const newZoom = newValue / 100;
            canvas.setZoom(newZoom);
            const tempCanvasDimensionsZoom = 1 + (100 - newValue)/100;

            let width = refContainer.current ? dimensions.width / (newValue/100) * tempCanvasDimensionsZoom: 0;
            let height = refContainer.current ? dimensions.height / (newValue/100) * tempCanvasDimensionsZoom : 0;
            if (newValue > 100) {
                width = refContainer.current ? dimensions.width * (newValue/100): 0;
                height = refContainer.current ? dimensions.height * (newValue/100) : 0;
            }
            canvas.setDimensions({ width, height }, { cssOnly: true });
            updateObjectsPosition(canvas, newZoom, previousZoom);
            canvas.renderAll();
        }
    },[canvas, renderIMGPosition, artworkIMG]);

    const undo = useCallback(() => {
        if (canvas) {
            canvas.undo(() => {
                setArtworkIMG(canvas._objects.find((o: fabric.Object) => o.name === ObjectName.ARTWORK));
            });
            setIsActiveUndo(canvas.canUndo());
            setIsActiveRedo(canvas.canRedo());
        }
    }, [canvas, fitToScreenValue]);

    const redo = useCallback(() => {
        if (canvas) {
            canvas.redo(() => {
                setArtworkIMG(canvas._objects.find((o: fabric.Object) => o.name === ObjectName.ARTWORK));
            });
            setIsActiveUndo(canvas.canUndo());
            setIsActiveRedo(canvas.canRedo());
        }
    }, [canvas, fitToScreenValue]);

    return (
        <div className="result-page">
            <div className="result-page-main-part">
                <div className="menu">
                    <div>
                        <div className="action-group fit-to-group" onClick={() => changeActiveMenuItem("rotate")}>
                            <FontAwesomeIcon icon={faArrowsRotate} />
                            <div>Rotate</div>
                        </div>
                        <div className="action-group fit-to-group" onClick={() => changeActiveMenuItem("move")}>
                            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                            <div>Move:</div>
                        </div>
                        <div className="action-group scale-group" onClick={() => changeActiveMenuItem("scale")}>
                            <FontAwesomeIcon icon={faArrowUpRightAndArrowDownLeftFromCenter} />
                            <div>Scale art</div>
                        </div>
                    </div>
                    <div className="undo-redo-group">
                        <div className={`undo ${isActiveUndo ? 'active' : 'disabled'}`} onClick={undo}>
                            <FontAwesomeIcon icon={faArrowTurnLeft} />
                            <div>Undo</div>
                        </div>
                        <div className={`redo ${isActiveRedo ? 'active' : 'disabled'}`} onClick={redo}>
                            <FontAwesomeIcon icon={faArrowTurnRight} />
                            <div>Redo</div>
                        </div>
                    </div>
                </div>
                <div className="details-menu">
                    {renderActiveGroup()}
                </div>
                { props.previewImage?.imageUrl &&
                  <div className="canvas" ref={refContainer}>
                      <canvas id="c" width={refContainer.current ? refContainer.current['offsetWidth'] : 0}
                          height={refContainer.current ? refContainer.current['offsetHeight'] : 0}/>
                      <div className="canvas-settings">
                          <Box className="slider-container" sx={{ width: 200 }}>
                              <Slider
                                  value={fitToScreenValue}
                                  min={1}
                                  step={1}
                                  max={200}
                                  onChange={handleFitToScreenChange}
                                  valueLabelDisplay="auto"
                                  aria-labelledby="non-linear-slider" />
                              <div>{`${fitToScreenValue}%`}</div>
                          </Box>
                          <div className="fit-to-screen" onClick={(e) => handleFitToScreenChange(e, 100)}>
                              fit to screen
                          </div>
                      </div>
                  </div>
                }
            </div>
            <div className="page-footer">
                <CustomButton name='go back' className="back-btn" disabled={false} active={true} onClick={onCheckAnother}/>
                <div className="caption">
                    <div className="safety-line">
                        <div className="safety-line-dots"/>
                        <div>safety line</div>
                    </div>
                    <div className="bleed-line">
                        <div className="bleed-line-dots"/>
                        <div>bleed line</div>
                    </div>
                    <div className="cut-line">
                        <div className="cut-line-dots"/>
                        <div>cut line</div>
                    </div>
                </div>
                <CustomButton name='finish' className="finish-btn" disabled={false} active={true} onClick={onFinish}/>
            </div>
        </div>
    );
};

export default ResultPage;
