import { useEffect, useState, useCallback } from 'react';

const useInterval = (): [
    handleSetInterval: (callback: () => void, interval: number) => void,
    handleClearInterval: () => void,
    intervalId: number | null
] => {
    const [intervalId, setIntervalId] = useState<number | null>(null);

    const handleSetInterval = useCallback((callback: () => void, interval: number) => {
        if (intervalId) {
            return;
        }
        const id = setInterval(callback, interval);
        setIntervalId(id as unknown as number);
    }, [intervalId]);

    const handleClearInterval = useCallback(() => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    }, [intervalId]);

    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    return [
        handleSetInterval,
        handleClearInterval,
        intervalId
    ];
};

export default useInterval;