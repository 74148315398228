import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch} from '@reduxjs/toolkit';
import { ErrorCode, useDropzone } from 'react-dropzone';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-light-svg-icons';
import { deleteSession, updateSession, uploadArtworkWithParams } from '../../api/api';
import { getUserInfo, getUserJobId } from '../../redux/reducers/userInfoReducer';
import { onChangeTaskStatus } from '../../redux/actionCreators';
import { DropZoneProps } from './IDropzone';

import './Dropzone.scss';

const supportedFileSize = _.isUndefined(process.env.REACT_APP_MAX_ARTWORK_SIZE) ? 20 : +process.env.REACT_APP_MAX_ARTWORK_SIZE;
export const supportedFileFormats = '.ai, .bmp, .eps, .jfif, .jpeg, .jpg, .pdf, .png, .psd, .svg, .tif, .tiff';

const errorMessages: {[key in ErrorCode]: string} = {
    [ErrorCode.TooManyFiles]: 'Please select only one file to upload',
    [ErrorCode.FileTooLarge]: `File size must be less than ${supportedFileSize} MB`,
    [ErrorCode.FileTooSmall]: `File size is too small`,
    [ErrorCode.FileInvalidType]: `File type must be ${supportedFileFormats}`,
};

const DropZone: FC<DropZoneProps> = ({disabled, active}) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const userInfo = useSelector(getUserInfo);
    const jobId = useSelector(getUserJobId);
    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = async () => {
                dispatch(onChangeTaskStatus( {status: 'uploading'}));
                // await dispatch(deleteSession());
                // await dispatch(updateSession());

                const data = new FormData();
                data.set('file', file, file.name);
                data.set('shape', userInfo.shape.value);
                data.set('width', `${+userInfo.width}`);
                data.set('height', `${+userInfo.height}`);

                await dispatch(uploadArtworkWithParams(data, jobId));
            };
            reader.readAsArrayBuffer(file);
        });
    }, [userInfo]);
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        multiple: false,
        disabled: disabled,
        accept: {
            'image/jpeg': [ '.jpg', '.jpeg' ],
            'image/png': [],
            'image/tiff': [],
            'image/vnd.adobe.photoshop':['.psd'],
            'image/x-photoshop':[],
            'image/svg+xml':[],
            'application/pdf': [],
            'application/x-photoshop': [],
            'application/photoshop': [],
            'application/psd': [],
            'application/postscript': []
        },
        maxSize: supportedFileSize * 1024 * 1024
    });

    const fileRejectionItems = useMemo(() => fileRejections.map(({ file, errors }) => (
        <>
            {errors.map(e => (
                <div key={e.code} about={file.type} className={e.code}>
                    {errorMessages[e.code as ErrorCode]}
                </div>
            ))}
        </>
    )), [fileRejections]);

    return (
        <div className={`dropzone-container ${disabled ? `disabled-dropzone` : `enabled-dropzone`} ${active ? `active` : `smooth`}`}>
            <section {...getRootProps()}>
                <input name='dropzone' type='file' {...getInputProps()} />
                <div className="dropzone-img">
                    <FontAwesomeIcon icon={faUpload} />
                </div>
                <div className="blue-text">Select a file to upload</div>
                <div>or drag and drop it here</div>
                <div className='dropzone-error-message'>{fileRejectionItems}</div>
            </section>
        </div>
    );
};

export default DropZone;
