import * as React from 'react';
import { IHeader } from './IHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo,
    faUpload,
    faPenToSquare,
    faFilePdf,
    faXmark,
    faCircleCheck,
    faTimesCircle
} from '@fortawesome/pro-light-svg-icons';
import { useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

import { actionCreators } from '../../redux';
import { sendExitMessage } from '../../utils/messageService';

import './Header.scss';

const Header: React.FC<IHeader> = (props) => {
    const dispatch = useDispatch();

    const {
        onChangeShape,
        onChangeWidth,
        onChangeHeight,
        onChangeLoadingStatus,
        onChangeTaskStatus,
        setFinalArtwork
    } = useMemo(
        () => bindActionCreators(actionCreators, dispatch),
        [dispatch]
    );

    const changeNavigationItemToUpload = useCallback(() => {
        if (props.active === 'preview' || props.active === 'edit') {
            onChangeWidth('');
            onChangeHeight('');
            onChangeShape(null);
            onChangeTaskStatus( {status: ''});
            onChangeLoadingStatus(false);
            setFinalArtwork(null);
        }
    }, [props.active]);

    const changeNavigationItemToEdit = useCallback(() => {
        if (props.active === 'preview') {
            props.onChangeActiveTab( 'edit');
        }
    }, [props.active]);

    const onExit = () => {
        sendExitMessage();
    };

    return (
        <header className="app-header">
            <div className="help" onClick={(e) => props.onChangeActiveTab(props.active === 'help' ? '' : 'help')}>
                {props.active === 'help' ?
                    <>
                        <FontAwesomeIcon icon={faXmark} />
                        <span>close help</span>
                    </> :
                    <>
                        <FontAwesomeIcon icon={faCircleInfo} />
                        <span>help</span>
                    </>}
            </div>
            <div className={`menu-item ${props.active === 'upload' ? 'active' : ''} 
            ${(props.active === 'edit' || props.active === 'preview') ? 'active-breadcrumb-item' : 'disabled-breadcrumb-item'}`}
            onClick={changeNavigationItemToUpload}
            >
                <FontAwesomeIcon icon={(props.active === 'edit' || props.active === 'preview') ? faCircleCheck : faUpload} />
                <span>upload</span>
            </div>
            <div className={`menu-item ${props.active === 'edit' ? 'active' : ''}
            ${(props.active === 'preview') ? 'active-breadcrumb-item' : 'disabled-breadcrumb-item'}`}
            onClick={changeNavigationItemToEdit}
            >
                <FontAwesomeIcon icon={(props.active === 'preview') ? faCircleCheck: faPenToSquare} />
                <span>edit</span>
            </div>
            <div className={`menu-item disabled-breadcrumb-item ${props.active === 'preview' ? 'active' : ''}
            `}>
                <FontAwesomeIcon icon={faFilePdf} />
                <span>preview</span>
            </div>
            <div className="exit"
                onClick={onExit}>
                <FontAwesomeIcon icon={faTimesCircle} />
                <span>exit</span>
            </div>
        </header>
    );
};
export default Header;
