import axios from "axios";
import _ from "lodash";

import {
    onChangeFinalTaskStatus,
    onChangeTaskStatus,
    onChangeUploadStatusInfo,
    setFinalErrorTaskStatusMessage, setUploadError
} from "../redux/actionCreators";

class ApiError extends Error {
    httpCode: number;

    constructor(httpCode: number, message: string) {
        super(message);
        this.httpCode = httpCode;
    }
}

export const get = (url: string) => fetch(url).then(handleResponse);

export const destroy = (url: string) => {
    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(handleResponse);
};

export const post = (url: string, data = {}) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(handleResponse);
};

export const put = (url: string, data = {}) => {
    return fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(handleResponse);
};

export const handleResponse = async (response: any) => {
    const contentType = response.headers.get('Content-Type');
    let body;
    switch (contentType) {
    case 'application/json':
    case 'application/vnd.spring-boot.actuator.v3+json':
    case 'application/octet-stream':
        body = await response.json();
        break;
    case 'text/html':
        body = await response.text();
        break;
    default:
        body = await response.body;
    }
    if (response.ok) {
        return body;
    } else {
        const message = contentType === 'application/json' ? body.message : body;
        throw new ApiError(response.status, message);
    }
};

export const refreshSession = () => {
    const url = `/api/kdpl-preflight-service/sessions/refreshSession`;
    return post(url);
};

export const destroySession = () => {
    const url = `/api/kdpl-preflight-service/sessions/destroySession`;
    return destroy(url);
};

export const deleteSession = () => async () => {
    try {
        await destroySession();
    } catch (error) {
        console.error(error);
    }
};

export const updateSession = () => async () => {
    try {
        await refreshSession();
    } catch (error) {
        console.error(error);
    }
};

export const getReportFileByName = (filename: string, jobId: string) => async () => {
    try {
        const url = `/api/kdpl-preflight-service/reports/${filename}?jobId=${jobId}`;
        const file = await fetch(url).then((response) => response.blob());
        return file;
    } catch (error) {
        console.error(error);
    }
};

export const getTaskStatus = (jobId: string) => async (dispatch: any) => {
    try {
        const url = `/api/kdpl-preflight-service/taskStatus?jobId=${jobId}`;
        await fetch(url)
            .then(res => res.clone().json())
            .then(status => dispatch(onChangeTaskStatus(status)));
    } catch (error) {
        console.error(error);
        dispatch(onChangeTaskStatus({ status: 'failed' }));
    }
};

export const getResult = (jobId: string) => async () => {
    try {
        const url = `/api/kdpl-preflight-service/resources/getResult?jobId=${jobId}`;
        const result = await fetch(url).then(async res => {
            if (res.ok) {
                return res.clone().json();
            } else {
                return {
                    error: await res.clone().text(),
                };
            }
        });
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const uploadArtworkWithParams = (settings: any, jobId: string) => async (dispatch: any) => {
    try {
        const url = `/api/kdpl-preflight-service/resources/uploadFile?jobId=${jobId}`;
        await axios.post(
            url,
            settings,
            {
                onUploadProgress:((event: any) => {
                    dispatch(onChangeUploadStatusInfo({
                        'progress': _.isUndefined(event.progress) ? 0 : (event.progress * 100).toFixed(0),
                        'estimated': _.isUndefined(event.estimated) ? 0 : event.estimated.toFixed(1),
                    }));
                })
            });
        dispatch(onChangeTaskStatus({ status: 'processing' }));
    } catch (error: any) {
        console.error(error);
        dispatch(onChangeTaskStatus({ status: 'failed' }));
        dispatch(setUploadError(error?.response?.data || { error: error.message }));
    }
};

export const uploadModifiedArtworkData = (modifiedIMGData: any, jobId: string) => async (dispatch: any) => {
    try {
        const url = `/api/kdpl-preflight-service/resources/action?jobId=${jobId}`;
        await axios.post(url, modifiedIMGData);
        dispatch(onChangeFinalTaskStatus({ status: 'processing' }));
    } catch (error: any) {
        console.error(error);
        dispatch(onChangeFinalTaskStatus({ status: 'failed' }));
        dispatch(setFinalErrorTaskStatusMessage(error?.response?.data || { error: error.message }));
    }
};

export const getFinalTaskStatus = (jobId: string) => async (dispatch:any) => {
    try {
        const url = `/api/kdpl-preflight-service/taskStatus?jobId=${jobId}`;
        await fetch(url)
            .then(res => res.clone().json())
            .then(status => dispatch(onChangeFinalTaskStatus(status)));
    } catch (error) {
        dispatch(setFinalErrorTaskStatusMessage(error));
        console.error(error);
    }
};
