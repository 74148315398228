import React, {FC, InputHTMLAttributes} from 'react';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './Button.scss';
interface ButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    name?: string;
    iconFA?: any;
    label?: string;
    disabled: boolean;
    active?: boolean;
    className?: string;
    iconFirst?: boolean;
    onClick?(): void;
}
const CustomButton: FC<ButtonProps> = ({name,iconFA,label,disabled,active,className,iconFirst,onClick}) => {
    return (
        <div className="btn-wrapper">
            <Button className={`${active ? 'active-btn' : 'hidden-btn'} ${className}`} onClick={onClick} disabled={disabled}>
                {iconFirst && <FontAwesomeIcon icon={iconFA} />}
                {name}
                {iconFA !== undefined && !iconFirst && <FontAwesomeIcon icon={iconFA} />}
            </Button>
            <div className="btn-label">{label}</div>
        </div>
    );
};
export default CustomButton;
