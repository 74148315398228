import { createSelector } from 'reselect';
import { ActionType } from '../actionTypes';
import { Action } from '../actions';
import { RootState } from './index';

const initialState = {
    jobId: '',
    width: '',
    height: '',
    shape: { value: '', label: '', disabledHeight: false },
};

const reducer = (state = initialState, action: Action): any => {
    switch (action.type) {
    case ActionType.CHANGE_JOBID:
        return {...state, jobId: action.payload};
    case ActionType.CHANGE_SHAPE:
        return {...state, shape: action.payload};
    case ActionType.CHANGE_WIDTH:
        return {...state, width: action.payload};
    case ActionType.CHANGE_HEIGHT:
        return {...state, height: action.payload};
    default: return state;
    }
};

export const getUserInfo = (state: RootState) => state.userInfo;
export const getUserJobId = createSelector(getUserInfo, userInfo => userInfo.jobId);

export default reducer;
