import { createSelector } from 'reselect';
import { ActionType } from '../actionTypes';
import { Action } from '../actions';
import { RootState } from './index';

const initialState = {
    isLoaded: false,
    taskStatus: { status: '' },
    uploadInfo: {
        progress: 0,
        estimated: 0
    },
    uploadError: { error: '' },
    finalTaskStatus: { status: '' },
    finalErrorTaskStatusMessage: '',
    finalArtwork : null,
    finalArtworkRenderPosition: {
        left: 0,
        top: 0,
        scale: 1,
        xc: 0,
        yc: 0,
        height: 0,
        width: 0,
    }
};

const reducer = (state = initialState, action: Action): any => {
    switch (action.type) {
    case ActionType.CHANGE_LOADING_STATUS:
        return {...state, isLoaded: action.payload};
    case ActionType.CHANGE_TASK_STATUS:
        return {...state, taskStatus: action.payload};
    case ActionType.CHANGE_UPLOAD_STATUS_INFO:
        return {...state, uploadInfo: action.payload};
    case ActionType.SET_RESULT_INFO:{
        return {...state, ...action.payload};
    }
    case ActionType.CHANGE_FINAL_TASK_STATUS:
        return {...state, finalTaskStatus: action.payload};
    case ActionType.SET_FINAL_ERROR_TASK_STATUS_MESSAGE:
        return {...state, finalErrorTaskStatusMessage: action.payload};
    case ActionType.SET_FINAL_ARTWORK:
        return {...state, finalArtwork: action.payload};
    case ActionType.SET_FINAL_ARTWORK_RENDER_POSITION:
        return {...state, finalArtworkRenderPosition: action.payload};
    case ActionType.SET_UPLOAD_ERROR:
        return {...state, uploadError: action.payload};
    default: return state;
    }
};

export const getResultInfo = (state: RootState) => state.resultInfo;
export const getLoadedStatus = createSelector(getResultInfo, resultInfo => resultInfo.isLoaded);
export const getTaskResultStatus = createSelector(getResultInfo, resultInfo => resultInfo.taskStatus);
export const getUploadStatusInfo = createSelector(getResultInfo, resultInfo => resultInfo.uploadInfo);
export const getFinalTaskResultStatus = createSelector(getResultInfo, resultInfo => resultInfo.finalTaskStatus);
export const getFinalErrorTaskStatusMessage = createSelector(getResultInfo, resultInfo => resultInfo.finalErrorTaskStatusMessage);
export const getFinalArtwork = createSelector(getResultInfo, resultInfo => resultInfo.finalArtwork);
export const getFinalArtworkRenderPosition = createSelector(getResultInfo, resultInfo => resultInfo.finalArtworkRenderPosition);
export const getUploadError = createSelector(getResultInfo, resultInfo => resultInfo.uploadError);

export default reducer;
