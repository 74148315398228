export enum Shape {
  Rectangle = 'rectangle',
  Circle = 'circle',
  Square = 'square',
  Oval  = 'oval',
}

export enum ShapeLabel {
  Rectangle = 'Rectangle',
  Circle = 'Circle',
  Square = 'Square',
  Oval  = 'Oval',
}

export enum Direction {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}
