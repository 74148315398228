import { PARENT_SITE_ORIGIN } from '../constants/constants';

const sendMessage = (message: object) => {
    PARENT_SITE_ORIGIN.forEach((origin: string) => {
        if (!origin) {
            return;
        }
        try {
            parent.postMessage(message, origin);
        } catch (e) {
            console.error('Failed to send message', e);
        }
    });
};

export const sendExitMessage = () => {
    sendMessage({ success: false });
};

export const sendAddToOrderMessage = (data: object) => {
    sendMessage({ ...data, success: true });
};
