import React, {FC, useCallback} from 'react';
import { Input } from '@mui/base';
import { InputProps } from './IInput';

import './Input.scss';

const CustomInput: FC<InputProps> = ({ name, label, value, disabled,hidden, onChange}) =>{
    const handleChange = useCallback((e:any) => {
        onChange(e.target.value);
    }, [onChange]);

    return(
        <div key={`${name}-wrapper`} className={hidden ? 'disabled-input-wrapper' : 'input-wrapper'}>
            <label htmlFor={name}>{label}</label>
            <Input id={name}
                key={name}
                type="number"
                onChange={handleChange}
                value={value}
                disabled={disabled}
                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}/>
        </div>
    );
};

export default CustomInput;