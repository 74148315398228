import * as React from 'react';
import { IHelpPage } from './IHelpPage';

import './HelpPage.scss';

const HelpPage: React.FC<IHelpPage> = (props) => {
    return (
        <div className={props.active ? 'active-help-page' : 'disabled-help-page'}>
            <main>
                <nav/>
                <section>
                    <h1>Guidelines</h1>
                </section>
            </main>
            <footer className="page-footer"/>
        </div>
    );
};
export default HelpPage;