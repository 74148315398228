import { combineReducers } from 'redux';
import userInfoReducer from './userInfoReducer';
import resultInfoReducer from './resultInfoReducer';

const reducers = combineReducers({
    userInfo: userInfoReducer,
    resultInfo: resultInfoReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;