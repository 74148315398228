import * as React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useBeforeUnload } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { deleteSession } from '../api/api';
import UploadPage from './uploadPage/UploadPage';

import './App.scss';

const basePath = '';

const App: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    useBeforeUnload(
        React.useCallback(() => dispatch(deleteSession()), [deleteSession])
    );
    window.onunload = () => {
        dispatch(deleteSession());
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route path={basePath} element={<UploadPage/>}/>
                <Route
                    path="*"
                    element={<Navigate to={basePath} replace={true}/>}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
